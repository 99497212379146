<template>
  <div class="project_page" @click.stop="storeBodyClick">
    <my-header isShowLogo="hide" path="home" page="pro"></my-header>
    <div class="tips">Double click to enter or exit full screen mode</div>

    <div class="searchBar_cpn">
      <SearchBar
        ref="searchBarRef"
        @resetProjectResult="resetProjectResultPage"
        @toSearchResultPage="toSearchResultEvent"
      ></SearchBar>
    </div>
  </div>
</template>

<script>
import SearchBar from "../components/SearchBar.vue";
import MyHeader from "../components/MyHeader.vue";
export default {
  components: {
    MyHeader,
    SearchBar,
  },
  props: {},
  data() {
    return {
      swiperImgList: [],
    };
  },
  async created() {},
  mounted() {},
  computed: {},
  methods: {
    // 跳转至结果页面
    async toSearchResultEvent(data) {
      this.$router.push(
        `/searchResult?country=${data.country}&type=${data.type}&year=${data.year}`
      );
    },
    // 自定义重置事件
    resetProjectResultPage() {
      this.$router.push("/project");
    },
    storeBodyClick() {
      this.$refs.searchBarRef.$refs.singinSeleceCountry.dropVisible = false;
      this.$refs.searchBarRef.$refs.singinSeleceType.dropVisible = false;
      this.$refs.searchBarRef.$refs.singinSeleceYear.dropVisible = false;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_fun.scss";
.project_page {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: url("../assets/images/product/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .searchBar_cpn {
    margin-top: vw(731);
  }
  .tips {
    position: fixed;
    top: vw(80);
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px !important;
    font-family: Helvetica;
    opacity: 0.5;
  }
}

.project_page {
  // min-width: 1200px 表示当屏幕宽度大于等于800px时，该样式生效。
  // max-width: 1200px 表示当屏幕宽度小于等于600px时，该样式生效。
  @media screen and (max-width: 1200px) {
    .searchBar_cpn {
      margin-top: 520px;
    }
  }
}
</style>
